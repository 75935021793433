<template>
    <div class="home">
        <header>
            <div class="welcome" style="padding: 0" @click="gotoOnlineOrder()">
                <!--       <div class="order">
          <a :href="`https://dinfo.me/onlineorder/#/home/menu/?restid=${getrestid}`">ORDER ONLINE</a>
        </div> -->
                <picture style="width: 100%; height: 100%;background-color: #fff;">
                    <source media="(min-width: 800px)"
                        srcset="static/mobile/banner.webp, static/mobile/banner.webp 2x" />
                    <source media="(min-width: 450px)"
                        srcset="static/mobile/banner.webp, static/mobile/banner.webp 2x" />
                    <img style="width: 100%" src="static/mobile/banner.webp" srcset="static/mobile/banner.webp 2x"
                        alt="" />
                </picture>
            </div>
            <div class="des" v-show="getadvwords">
                <h1>{{ getadvwords }}</h1>
                <h3>{{ getadvwords2 }}</h3>
            </div>
        </header>
        <div class="speciality section_container" v-if="getsubclass1">
            <div class="title">{{ getsubclass1 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt1" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/1-${item}.webp, static/mobile/1-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/1-${item}.webp, static/mobile/1-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/1-${item}.webp`" alt=""
                            :srcset="`static/mobile/1-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <div class="flavor section_container" v-if="getsubclass2">
            <div class="title">{{ getsubclass2 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt2" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/2-${item}.webp, static/mobile/2-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/2-${item}.webp, static/mobile/2-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/2-${item}.webp`" alt=""
                            :srcset="`static/mobile/2-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <div class="section3 section_container" v-if="getsubclass3">
            <div class="title">{{ getsubclass3 }}</div>
            <div class="content">
                <div class="item-card" v-for="item of getsubclasscnt3" :key="item">
                    <picture style="width: 100%">
                        <source media="(min-width: 800px)"
                            :srcset="`static/mobile/3-${item}.webp, static/mobile/3-${item}.webp 2x`" />
                        <source media="(min-width: 450px)"
                            :srcset="`static/mobile/3-${item}.webp, static/mobile/3-${item}.webp 2x`" />
                        <img style="width: 100%" :src="`static/mobile/3-${item}.webp`" alt=""
                            :srcset="`static/mobile/3-${item}.webp 2x`" />
                    </picture>
                </div>
            </div>
        </div>
        <div class="Beyondmenu">
            <div class="byeondmenutitle">
            <a class="roundedbutton" href="https://www.smorefood.com/f4d42g3n/alaska-crab-moreno-valley-92553/order-online?utm_source=gmb&utm_medium=website" target="_blank">Alaska Crab Beyond Menu</a>
             <!-- <iframe src="https://www.smorefood.com/f4d42g3n/alaska-crab-moreno-valley-92553/order-online?utm_source=gmb&utm_medium=website" width="100%" height="1920px" frameborder="0" title="Beyond menu"></iframe> -->
            </div>
        </div>
        <div class="yelpframe">
        <div class="Reservations">
            <div><h1>Reservations</h1></div>
            <iframe id="yelp-reservations-widget" frameborder="0" width="300" height="530" src="https://www.yelp.com/reservations/gSluAUqM4pLONiQwz783tA/widget?orientation=vertical&color-scheme=light" title="Make a reservation" > <a href="">Reserve at Alaska Crab on Yelp</a > </iframe>
        </div>
        <div class="Waitlist">
            <div><h1>Waitlist</h1></div>
            <iframe src="https://www.yelp.com/waitlist/alaska-crab-moreno-valley-4/widget" width="300" height="326" frameborder="0" title="Join our waitlist"></iframe>
        </div>
        </div>

        <footer>
            <div>
                <h3>Address &nbsp; Contact</h3>
                <div>{{ getaddress }}</div>
                <div>{{ gettel }}</div>
            </div>
            <div>
                <h3>Opening Hours</h3>
                <div v-for="(item, index) in getopenhours" v-bind:key="index">
                    {{ item }}
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import _service from "@/service"
    export default {
        data() {
            return {
                jsonData: {}
            }
        },
        mounted() {
            _service.getbasedatainfo().then((res) => {
                this.jsonData = res
                document.title = this.getcompany
                // console.log('aaaa==', this.jsonData)
            })
        },
        methods: {
            gotoOnlineOrder() {
                window.open('https://dinfo.me/onlineorder/#/home/menu/?restid=' + this.getrestid, '_self')
            }
        },
        computed: {
            getrestid() {
                return this.jsonData.restid
            },
            getcompany() {
                return this.jsonData.company
            },
            getcompany2() {
                return this.jsonData.company2
            },
            gettel() {
                return this.jsonData.tel
            },
            getaddress() {
                return this.jsonData.address
            },
            getopenhours() {
                // debugger
                // console.log(this.jsonData)
                return this.jsonData && this.jsonData.openhours ? this.jsonData.openhours.split(';') : null
            },
            getsubclass1() {
                return this.jsonData.subclass1
            },
            getsubclass2() {
                return this.jsonData.subclass2
            },
            getsubclass3() {
                return this.jsonData.subclass3
            },
            getadvwords() {
                return this.jsonData.advwords
            },
            getadvwords2() {
                return this.jsonData.advwords2
            },
            getsubclasscnt1() {
                return this.jsonData.subclasscnt1 ? this.jsonData.subclasscnt1 * 1 : 0
            },
            getsubclasscnt2() {
                return this.jsonData.subclasscnt2 ? this.jsonData.subclasscnt2 * 1 : 0
            },
            getsubclasscnt3() {
                return this.jsonData.subclasscnt3 ? this.jsonData.subclasscnt3 * 1 : 0
            }
        }
    }
</script>
<style scoped lang="scss">
    header {
        // margin: 1.45rem 1.2rem;
        background-image: url('~@/static/mobile/backimg.png');

        .welcome {
            background: #000; // #eaeaea;
            background-image: url('~@/static/mobile/backimg.png');
            // border-radius: 0.6rem;
            padding-top: 1.3rem;
            box-sizing: border-box;
            position: relative;

            h2 {
                font-size: 1.4rem;
                font-family: Arial;
                font-weight: bold;
                color: #f15a25; // #1d736c;
                margin: 0;
                text-transform: uppercase;
            }

            .order {
                width: 14rem;
                height: 3rem;
                background: #5f5e5dd6; // #1d736c;

                border-radius: 1.5rem;
                margin: 0 auto;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                // text-transform: uppercase;

                // top: 10rem;
                top: 68%;
                position: absolute;
                left: 50%;
                transform: translate(-50%);

                a {
                    color: #fff;
                    text-decoration: none;
                }
            }

            .img {
                position: absolute;
                top: 0.65rem;
                right: 1.15rem;
                width: 2.6rem;
                height: 2.6rem;
                background: #f15a25; // #1d736c;
                border-radius: 1.1rem;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            picture {
                background-color: #000;
            }
        }

        .des {
            // height: 3rem;
            // margin-top: 0.5rem;
            background: var(--bgcolor-primary);
            // border-radius: 0rem 0rem 1rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            font-size: 1.2rem;
            font-family: Arial;
            font-weight: bold;
            flex-direction: column;
            line-height: 1.2rem;
            background-image: url('~@/static/mobile/backimg.png');
            
            font-family: 'Lucida Handwriting', cursive;
            text-shadow: 2px 2px 5px #000;
            // font-size: 3rem;
            // font-weight: bold;
            // color: violet;
            // transform: rotate(0deg);

            h3 {
                padding-left: 3px;
                padding-right: 3px;
                line-height: 2rem;
                margin-block-start: 0.1em;
                margin-block-end: 0.1em;
            }

            // text-transform: uppercase;
        }
    }

    .speciality {
        background-color: #000; // #ffe6da;
        background-image: url('~@/static/mobile/backimg.png');
        color: #d98a6f; // #36BDB2; // #ff5101;
        font-family: 'Arial', cursive;
        text-shadow: 1px 1px 0px #000;

        .item-card {
            // border: 2px solid #ff5101;
        }
    }

    .flavor {
        background-color: #cbebfd;
        background-image: url('~@/static/mobile/backimg.png');
        font-family: 'Arial', cursive;
        text-shadow: 2px 2px 5px #000;
        color: #0069a4;

        .item-card {
            // border: 2px solid #0069a4;
        }
    }

    .section3 {
        background-color: #eeeeee;
        background-image: url('~@/static/mobile/backimg.png');
        color: #555555;

        .item-card {
            // border: 2px solid #555555;
        }
    }

    .section_container {
        padding: 0 1.2rem;

        // margin-bottom: 1.4rem;
        .content {
            // display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            gap: calc((10vw - 0.7rem) / 2);
            text-align: left;
        }

        .title {
            font-size: 2rem;
            // font-family: Arial;
            font-weight: bold;
            padding: 1.5rem 0;
            // text-transform: uppercase;
        }

        .item-card {
            width: 30%;
            border-radius: 10px;
            // background: #bdbdbd;
            overflow: hidden;
            // border-radius: 0.6rem;
            margin: 1rem 0rem;
            display: inline-block;

            // height: 15rem;
            img {
                width: 100%;
            }
        }
    }

    .section_container .item-card:nth-child(3n + 2) {
        margin-left: calc((10vw - 0.7rem) / 2);
        margin-right: calc((10vw - 0.7rem) / 2);
    }
    
    .yelpframe {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .Beyondmenu {
        background-image: url('~@/static/mobile/beyondmenu.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
        .byeondmenutitle {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
        }
        .roundedbutton {
            // margin: 50px;
            font-size: 2rem;
            color: #fff;
            text-decoration: none;
            background-color: #f15a251f;
            padding: 20px;
            border-radius: 30px;
            border-style: solid;
            border-width: thin;
            background-image: linear-gradient(160deg, rgb(255 198 163 / 23%) 20%, rgb(241 90 37 / 5%) 80%);
            cursor: pointer;
            // font-size: 16px;
        }
        .roundedbutton:hover {
          background-color: #45a049a1;
        }
    }

    footer {
        background-color: var(--bgcolor-primary);
        // background-image: url('~@/static/mobile/backimg.png');
        font-family: 'Arial', cursive;
        text-shadow: 2px 2px 5px #000;
        color: #c5c5c5;
        padding: 1.5rem 0;
        line-height: 2;

        h3 {
            font-size: 1.5rem;
            font-family: Arial;
            font-weight: bold;
            color: #fff;
        }
    }

    @media screen and (min-width: 500px) {
        .home {
            // max-width: 1200px;
            // margin: auto;
        }

        header {
            margin: 0rem;

            .welcome {
                // height: 50rem;

                h2 {
                    font-size: 3rem;
                    // margin-top: 2.9rem;
                }

                .order {
                    top: 78%;
                    width: 20rem;
                    height: 5.1rem;
                    border-radius: 2.55rem;
                    font-weight: bold;
                    font-size: 1.5rem;
                }

                .img {
                    width: 7rem;
                    height: 7rem;
                    border-radius: 3.5rem;
                    right: 5.3rem;
                    top: 2rem;
                }
            }

            .des {
                // height: 5.61rem;
                font-size: 2rem;
                // margin: 1rem 0;
                padding: 2rem 0;
                line-height: 2rem;
            }
        }

        .section_container {
            padding: 2.25rem;

            // margin-bottom: 2.5rem;
            .content {}

            .title {
                font-size: 3.4rem;
                padding-top: 4.5rem;
            }

            .item-card {
                // border-radius: 1.5rem;
                height: auto;

                img {}
            }
        }

        footer {
            display: flex;
            justify-content: space-around;
            align-items: center;
            font-size: 2rem;

            h3 {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }
        }
    }

    @media screen and (max-width: 300px) {
        .section_container .item-card {
            width: 45%;
        }

        .section_container .content {
            gap: calc(10% / 2);
        }
    }

    @media screen and (min-width: 1200px) {
        header .welcome .order[data-v-9ea40744] {
            top: 78%;
            width: 24rem;
            height: 7rem;
            font-weight: bold;
            font-size: 2.2rem;
            border-radius: 3.5rem;
        }
    }
</style>